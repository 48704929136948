import { FETCH_PATIENT_DATA_SUCCESS } from "./actionTypes";

const initialState = {
  data: [],
  isLoading: false,
};

const PatientReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PATIENT_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        totalItems:action.totalItems,
      };
    default:
      return state;
  }
};

export default PatientReducer;
