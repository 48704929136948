export const UPDATE_ROLES_REQUEST = "UPDATE_ROLES_REQUEST";
export const UPDATE_APPOINTMENT_PERMISSION = "UPDATE_APPOINTMENT_PERMISSION";
export const UPDATE_PATIENT_MANAGEMENT_PERMISSION =
  "UPDATE_PATIENT_MANAGEMENT_PERMISSION";
export const UPDATE_USER_MANAGEMENT_PERMISSION =
  "UPDATE_USER_MANAGEMENT_PERMISSION";
export const UPDATE_ENQUIRIES_PERMISSION = "UPDATE_ENQUIRIES_PERMISSION";
export const UPDATE_DIRECTOR_PERMISSION = "UPDATE_DIRECTOR_PERMISSION";
export const UPDATE_REVENUE_PERMISSION = "UPDATE_REVENUE_PERMISSION";
export const UPDATE_FEEDBACK_PERMISSION = "UPDATE_FEEDBACK_PERMISSION";
export const UPDATE_TREATMENT_MANAGEMENT_PERMISSION =
  "UPDATE_TREATMENT_MANAGEMENT_PERMISSION";
export const UPDATE_JOBSHEET_PERMISSION = "UPDATE_JOBSHEET_PERMISSION";
export const UPDATE_DRIVE_PERMISSION = "UPDATE_DRIVE_PERMISSION";
export const UPDATE_TREATMENTPLAN_PERMISSION =
  "UPDATE_TREATMENTPLAN_PERMISSION";
// create and edit //
export const UPDATE_APPOINTMENT_CREATE = "UPDATE_APPOINTMENT_CREATE";
export const UPDATE_APPOINTMENT_EDIT = "UPDATE_APPOINTMENT_EDIT";
export const UPDATE_USER_MANAGEMENT_CREATE = "UPDATE_USER_MANAGEMENT_CREATE";
export const UPDATE_USER_MANAGEMENT_EDIT = "UPDATE_USER_MANAGEMENT_EDIT";
export const UPDATE_PATIENT_MANAGEMENT_CREATE =
  "UPDATE_PATIENT_MANAGEMENT_CREATE";
export const UPDATE_PATIENT_MANAGEMENT_EDIT = "UPDATE_PATIENT_MANAGEMENT_EDIT";
export const UPDATE_ENQUIRIES_CREATE = "UPDATE_ENQUIRIES_CREATE";
export const UPDATE_ENQUIRIES_EDIT = "UPDATE_ENQUIRIES_EDIT";
export const UPDATE_DIRECTOR_CREATE = "UPDATE_DIRECTOR_CREATE";
export const UPDATE_DIRECTOR_EDIT = "UPDATE_DIRECTOR_EDIT";
export const UPDATE_REVENUE_CREATE = "UPDATE_REVENUE_CREATE";
export const UPDATE_REVENUE_EDIT = "UPDATE_REVENUE_EDIT";
export const UPDATE_FEEDBACK_CREATE = "UPDATE_FEEDBACK_CREATE";
export const UPDATE_FEEDBACK_EDIT = "UPDATE_FEEDBACK_EDIT";
export const UPDATE_TREATMENT_MANAGEMENT_CREATE =
  "UPDATE_TREATMENT_MANAGEMENT_CREATE";
export const UPDATE_TREATMENT_MANAGEMENT_EDIT =
  "UPDATE_TREATMENT_MANAGEMENT_EDIT";
export const UPDATE_JOBSHEET_CREATE = "UPDATE_JOBSHEET_CREATE";
export const UPDATE_JOBSHEET_EDIT = "UPDATE_JOBSHEET_EDIT";
export const UPDATE_DRIVE_CREATE = "UPDATE_DRIVE_CREATE";
export const UPDATE_DRIVE_EDIT = "UPDATE_DRIVE_EDIT";
export const UPDATE_TREATMENTPLAN_CREATE = "UPDATE_TREATMENTPLAN_CREATE";
export const UPDATE_TREATMENTPLAN_EDIT = "UPDATE_TREATMENTPLAN_EDIT";
// users list//
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";
// HOLIDAY MANAGEMENT
export const HOLIDAY_MANAGEMENT_CREATE = "HOLIDAY_MANAGEMENT_CREATE";
export const HOLIDAY_MANAGEMENT_EDIT = "HOLIDAY_MANAGEMENT_EDIT";
export const UPDATE_HOLIDAY_MANAGEMENT_PERMISSION="UPDATE_HOLIDAY_MANAGEMENT_PERMISSION";
