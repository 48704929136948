import {
  UPDATE_ROLES_REQUEST,
  UPDATE_APPOINTMENT_PERMISSION,
  UPDATE_PATIENT_MANAGEMENT_PERMISSION,
  UPDATE_USER_MANAGEMENT_PERMISSION,
  UPDATE_ENQUIRIES_PERMISSION,
  UPDATE_DIRECTOR_PERMISSION,
  UPDATE_REVENUE_PERMISSION,
  UPDATE_FEEDBACK_PERMISSION,
  UPDATE_TREATMENT_MANAGEMENT_PERMISSION,
  UPDATE_JOBSHEET_PERMISSION,
  UPDATE_DRIVE_PERMISSION,
  UPDATE_TREATMENTPLAN_PERMISSION,
  GET_ALL_USERS_FAILURE,
  GET_ALL_USERS_SUCCESS,
  UPDATE_APPOINTMENT_CREATE,
  UPDATE_APPOINTMENT_EDIT,
  UPDATE_USER_MANAGEMENT_CREATE,
  UPDATE_USER_MANAGEMENT_EDIT,
  UPDATE_PATIENT_MANAGEMENT_CREATE,
  UPDATE_PATIENT_MANAGEMENT_EDIT,
  UPDATE_ENQUIRIES_CREATE,
  UPDATE_ENQUIRIES_EDIT,
  UPDATE_DIRECTOR_CREATE,
  UPDATE_DIRECTOR_EDIT,
  UPDATE_REVENUE_CREATE,
  UPDATE_REVENUE_EDIT,
  UPDATE_FEEDBACK_CREATE,
  UPDATE_FEEDBACK_EDIT,
  UPDATE_TREATMENT_MANAGEMENT_CREATE,
  UPDATE_TREATMENT_MANAGEMENT_EDIT,
  UPDATE_JOBSHEET_CREATE,
  UPDATE_JOBSHEET_EDIT,
  UPDATE_DRIVE_CREATE,
  UPDATE_DRIVE_EDIT,
  UPDATE_TREATMENTPLAN_CREATE,
  UPDATE_TREATMENTPLAN_EDIT,
  UPDATE_HOLIDAY_MANAGEMENT_PERMISSION,
  HOLIDAY_MANAGEMENT_CREATE,
  HOLIDAY_MANAGEMENT_EDIT,
} from "./actionTypes";

const initialState = {
  isLoading: false,
  error: null,
  updatedRoles: null,
  appointmentPermission: 0,
  patientManagementPermission: 0,
  userManagementPermission: 0,
  enquiresPermission: 0,
  directorPermission: 0,
  revenuePermission: 0,
  feedbackPermission: 0,
  treatmentPermission: 0,
  jobsheetPermission: 0,
  drivePermission: 0,
  treatmentPlanPermission: 0,
  holidaypermission:0,
  //create and edit //
  holiday_create:0,
  holiday_edit:0,
  appointment_create: 0,
  appointment_edit: 0,
  user_management_create: 0,
  user_management_edit: 0,
  patient_management_create: 0,
  patient_management_edit: 0,
  enquires_create: 0,
  enquires_edit: 0,
  write_to_director_create: 0,
  write_to_director_edit: 0,
  revenue_management_create: 0,
  revenue_management_edit: 0,
  treatment_plan_create: 0,
  treatment_plan_edit: 0,
  feedback_management_create: 0,
  feedback_management_edit: 0,
  treatment_management_create: 0,
  treatment_management_edit: 0,
  job_sheet_create: 0,
  job_sheet_edit: 0,
  drive_create: 0,
  drive_edit: 0,
  listData: [],
};

const PermissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ROLES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case UPDATE_APPOINTMENT_PERMISSION:
      return {
        ...state,
        appointmentPermission: action.payload,
      };
    case UPDATE_PATIENT_MANAGEMENT_PERMISSION:
      return {
        ...state,
        patientManagementPermission: action.payload,
      };
    case UPDATE_USER_MANAGEMENT_PERMISSION:
      return {
        ...state,
        userManagementPermission: action.payload,
      };
    case UPDATE_ENQUIRIES_PERMISSION:
      return {
        ...state,
        enquiresPermission: action.payload,
      };
    case UPDATE_DIRECTOR_PERMISSION:
      return {
        ...state,
        directorPermission: action.payload,
      };
    case UPDATE_REVENUE_PERMISSION:
      return {
        ...state,
        revenuePermission: action.payload,
      };
    case UPDATE_FEEDBACK_PERMISSION:
      return {
        ...state,
        feedbackPermission: action.payload,
      };
    case UPDATE_TREATMENT_MANAGEMENT_PERMISSION:
      return {
        ...state,
        treatmentPermission: action.payload,
      };
    case UPDATE_JOBSHEET_PERMISSION:
      return {
        ...state,
        jobsheetPermission: action.payload,
      };
    case UPDATE_DRIVE_PERMISSION:
      return {
        ...state,
        drivePermission: action.payload,
      };
    case UPDATE_TREATMENTPLAN_PERMISSION:
      return {
        ...state,
        treatmentPlanPermission: action.payload,
      };
    case  UPDATE_HOLIDAY_MANAGEMENT_PERMISSION:
      return{
        ...state,
        holidaypermission: action.payload,
      }
    case  HOLIDAY_MANAGEMENT_CREATE:
      return{
        ...state,
        holiday_create:action.payload,
      }
    case HOLIDAY_MANAGEMENT_EDIT:
      return{
        ...state,
        holiday_edit:action.payload,
      }
    case UPDATE_APPOINTMENT_CREATE:
      return {
        ...state,
        appointment_create: action.payload,
      };
    case UPDATE_APPOINTMENT_EDIT:
      return {
        ...state,
        appointment_edit: action.payload,
      };
    case UPDATE_USER_MANAGEMENT_CREATE:
      return {
        ...state,
        user_management_create: action.payload,
      };
    case UPDATE_USER_MANAGEMENT_EDIT:
      return {
        ...state,
        user_management_edit: action.payload,
      };
    case UPDATE_PATIENT_MANAGEMENT_CREATE:
      return {
        ...state,
        patient_management_create: action.payload,
      };
    case UPDATE_PATIENT_MANAGEMENT_EDIT:
      return {
        ...state,
        patient_management_edit: action.payload,
      };
    case UPDATE_ENQUIRIES_CREATE:
      return {
        ...state,
        enquires_create: action.payload,
      };
    case UPDATE_ENQUIRIES_EDIT:
      return {
        ...state,
        enquires_edit: action.payload,
      };
    case UPDATE_DIRECTOR_CREATE:
      return {
        ...state,
        write_to_director_create: action.payload,
      };
    case UPDATE_DIRECTOR_EDIT:
      return {
        ...state,
        write_to_director_edit: action.payload,
      };
    case UPDATE_REVENUE_CREATE:
      return {
        ...state,
        revenue_management_create: action.payload,
      };
    case UPDATE_REVENUE_EDIT:
      return {
        ...state,
        revenue_management_edit: action.payload,
      };
    case UPDATE_FEEDBACK_CREATE:
      return {
        ...state,
        feedback_management_create: action.payload,
      };
    case UPDATE_FEEDBACK_EDIT:
      return {
        ...state,
        feedback_management_edit: action.payload,
      };
    case UPDATE_TREATMENT_MANAGEMENT_CREATE:
      return {
        ...state,
        treatment_management_create: action.payload,
      };
    case UPDATE_TREATMENT_MANAGEMENT_EDIT:
      return {
        ...state,
        treatment_management_edit: action.payload,
      };
    case UPDATE_JOBSHEET_CREATE:
      return {
        ...state,
        job_sheet_create: action.payload,
      };
    case UPDATE_JOBSHEET_EDIT:
      return {
        ...state,
        job_sheet_edit: action.payload,
      };
    case UPDATE_DRIVE_CREATE:
      return {
        ...state,
        drive_create: action.payload,
      };
    case UPDATE_DRIVE_EDIT:
      return {
        ...state,
        drive_edit: action.payload,
      };
    case UPDATE_TREATMENTPLAN_CREATE:
      return {
        ...state,
        treatment_plan_create: action.payload,
      };
    case UPDATE_TREATMENTPLAN_EDIT:
      return {
        ...state,
        treatment_plan_edit: action.payload,
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        listData: action.payload, // Update the users array with the fetched data
        error: null, // Clear any previous error
      };
    case GET_ALL_USERS_FAILURE:
      return {
        ...state,
        listData: [], // Clear the users array on failure
        error: action.payload, // Set the error message
      };
    default:
      return state;
  }
};
export default PermissionsReducer;
