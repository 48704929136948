import {
  GET_REVENUE_MANAGEMENT_SUCCESS,
  GET_REVENUE_MANAGEMENT_REQUEST,
  GET_REVENUE_MANAGEMENT_FAILURE,
  FETCH_APPOINTMENTS,
} from "./actionTypes";

const initialState = {
  data: [],
  appointData: [],
  loading: false,
  error: null,
};

const ReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REVENUE_MANAGEMENT_REQUEST:
      return { ...state, loading: true };
    case GET_REVENUE_MANAGEMENT_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case GET_REVENUE_MANAGEMENT_FAILURE:
      return { ...state, error: action.error, loading: false };
    case FETCH_APPOINTMENTS:
      return {
        ...state,
        appointData: action.payload,
      };
    default:
      return state;
  }
};

export default ReportReducer;
