import thunk from "redux-thunk";
import createDebounce from "redux-debounced";
import { configureStore } from "@reduxjs/toolkit";
import { reducers } from "../../reducer"; // Make sure you're importing your reducers correctly

// init middleware
const middleware = [thunk, createDebounce()];

// Create store
const store = configureStore({
  reducer: reducers, // Use the root reducer directly
  middleware: middleware,
  devTools: true, // Enable DevTools only in development
});

export { store };

