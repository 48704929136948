import { GET_TREATMENT } from "./actionTypes";

const initialState = {
  listData: [],
  error: null,
  isLoading: false,
};

const TreatmentPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TREATMENT:
      return { ...state, listData: action.payload, error: null };
    default:
      return state;
  }
};

export default TreatmentPlanReducer;
