import { FORGOT_PASSWORD, RESET_PASSWORD } from "./actionType";

const initialState = {
  forgot: [],
  reset: [],
  defaultValue: {
    password: "",
    email: "",
    username: "",
  },
};

const Authorized = (state = initialState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD:
      return { ...state, forgot: action.forgot };
    case RESET_PASSWORD:
      return { ...state, reset: action.reset };
    default:
      return { ...state };
  }
};

export default Authorized;
