import {
  GET_REVENUE_REQUEST,
  GET_REVENUE_SUCCESS,
  GET_REVENUE_FAILURE,
  SET_POP_DATA,
  GET_REVENUE_SUCCESS_SECOND,
  SET_TARGET_SUMMARY,
} from "./actionTypes";

const initialState = {
  loading: false,
  listData: [],
  popData: [],
  listData2: [],
  targetSummary: "",
};

const RevenueReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REVENUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_REVENUE_SUCCESS:
      return {
        ...state,
        loading: false,
        listData: action.payload,
      };
    case GET_REVENUE_SUCCESS_SECOND:
      return {
        ...state,
        loading: false,
        listData2: action.payload,
      };
    case SET_POP_DATA:
      return {
        ...state,
        popData: action.payload,
      };
    case SET_TARGET_SUMMARY:
      return {
        ...state,
        targetSummary: action.payload,
      };
    case GET_REVENUE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default RevenueReducer;
