import {
  SET_FEEDBACK_DATA,
  SET_TOTAL_RATING,
  SET_RATINGS,
} from "./actionTypes";

// Initial state for feedback data
const initialState = {
  listData: [],
  totalRating: [],
  ratings: [],
};

// Feedback reducer
const FeedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FEEDBACK_DATA:
      return {
        ...state,
        listData: action.payload,
      };

    case SET_TOTAL_RATING:
      return {
        ...state,
        totalRating: action.payload,
      };

    case SET_RATINGS:
      return {
        ...state,
        ratings: action.payload,
      };

    default:
      return state;
  }
};

export default FeedbackReducer;
