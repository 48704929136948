import Authorized from "../auth/reducer";
import PermissionsReducer from "../views/user_management/reducer";
import PatientReducer from "../views/patient_management/reducer";
import TreatmentPlanReducer from "../views/treatment_plan/reducer";
import JobsReducer from "../views/jobsheet/reducer";
import FeedbackReducer from "../views/feedback_management/reducer";
import RevenueReducer from "../views/invoice_management/reducer";
import ReportReducer from "../views/reports/reducer";

export const reducers = {
  Authorized,
  PermissionsReducer,
  PatientReducer,
  TreatmentPlanReducer,
  JobsReducer,
  FeedbackReducer,
  RevenueReducer,
  ReportReducer,
};
